<template>
  <div>
    <!-- <div v-if="!$apollo.queries.get_hackathon_hackathon.loading"> -->
    <div>
      <div class="header pb-6 d-flex align-items-center">
        <!-- Mask -->
        <span class="mask bg-white opacity-8"></span>
        <!-- Header container -->
        <b-container fluid class="d-flex">
          <b-row>
            <!-- <b-col sm="12" md="auto" class="mt-4">
                            <div
                                @click="$router.back()"
                                class="icon icon-shape rounded-circle shadow bg-primary text-white"
                            >
                                <i class="fa fa-arrow-left"></i>
                            </div>
                        </b-col> -->
            <!-- Hackathon name -->
            <b-col sm="12" md="auto" class="mt-2">
              <b-row>
                <b-col>
                  <h1 class="text-primary display-1 mb--2" v-if="hackathon.id">
                    {{ hackathon.name }}
                  </h1>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <small class="text-muted"
                    >By: {{ hackathon.organization.name }}
                  </small>
                  <small
                    class="text-muted"
                    v-if="hackathon.management_team_visibile"
                    >(<a :href="'mailto:' + hackathon.creator.email">{{
                      hackathon.creator.name
                    }}</a
                    >)</small
                  >
                </b-col>
              </b-row>
            </b-col>
            <!-- Edit button -->
            <b-col class="text-right mt-4 pt-3">
              <h2>
                <router-link
                  v-if="hackathon.is_creator || hackathon.is_manager"
                  :to="{
                    path: `/hackathon/${encodeURIComponent(
                      hackathon_id
                    )}/timeline`,
                  }"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
              </h2>
            </b-col>

            <b-col sm="6" md="auto" class="mt-4 mb-2 pt-2 text-right">
              <b-row>
                <b-col>
                  <b-button
                    :pill="true"
                    variant="info"
                    size="md"
                    type="default"
                    block
                    @click="
                      $router.push({
                        path: '/register',
                        query: {
                          register: true,
                        },
                      })
                    "
                  >
                    Register
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container fluid class="mt--6">
        <!-- Hackathon Details -->
        <b-row>
          <b-col>
            <p v-html="hackathon.detail_description"></p>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <!-- Prizes -->
          <b-col sm="12" md="6" v-if="hackathon.has_prizes">
            <b-card>
              <b-row>
                <b-col>
                  <PrizesWidget
                    :hackathon_id="hackathon_id"
                    :hackathon_uid="hackathon_uid"
                    un_auth
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- Goals -->
          <b-col sm="12" md="6" v-if="hackathon.has_goals">
            <b-card>
              <b-row>
                <b-col>
                  <GoalsWidget
                    :hackathon_id="hackathon_id"
                    :hackathon_uid="hackathon_uid"
                    un_auth
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Ideas -->
        <b-row class="mt-4">
          <b-col sm="12">
            <b-row class="mb-4">
              <b-col sm="auto">
                <h1 class="display-3 text-primary pb-0 mb-0">Ideas</h1>
              </b-col>
              <!-- Search -->
              <b-col sm="3" class="pr-0">
                <b-form
                  class="navbar-search form-inline navbar-search-light"
                  id="navbar-search-main"
                  @submit.prevent="on_search_submit"
                >
                  <b-form-group class="mb-0">
                    <b-input-group
                      class="input-group-alternative input-group-merge"
                    >
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="fas fa-search"></i
                        ></span>
                      </div>
                      <b-form-input
                        placeholder="Search"
                        type="text"
                        v-model="search.search_term"
                        @keyup="on_keyup_search"
                      >
                      </b-form-input>

                      <input type="submit" ref="search_form_submit" hidden />
                    </b-input-group>
                  </b-form-group>
                  <Transition>
                    <i
                      v-if="search.is_loading"
                      class="fas fa-spinner fa-spin ml--4 mt-2 text-muted"
                    ></i>
                  </Transition>
                  <button
                    type="button"
                    class="close"
                    data-action="search-close"
                    data-target="#navbar-search-main"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </b-form>
              </b-col>
            </b-row>
            <!-- Hackathon Idea Cards -->

            <b-row v-if="filtered_hackathon_ideas.length > 0">
              <HackathonListIdeaCard
                v-for="idea in filtered_hackathon_ideas"
                :key="idea.id"
                :id="idea.id"
                :hackathon_id="hackathon_id"
                :name="idea.name"
                :description="idea.description"
                :likes="idea.number_of_likes"
                :liked_id="idea.liked_id"
                :is_creator="idea.is_creator"
                :team="idea.team"
                :is_part_of_team="is_part_of_team"
                @liked="idea.number_of_likes++"
                @new_like_id="
                  (id) => {
                    idea.liked_id = id;
                  }
                "
                @unliked="idea.number_of_likes--"
                :clickable="false"
              />
            </b-row>
            <b-row
              v-else-if="
                filtered_hackathon_ideas.length < 1 &&
                hackathon_ideas.length > 0
              "
            >
              <b-col class="text-center">
                <h3 class="text-muted">No ideas found matching your search</h3>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col class="text-center">
                <h3 class="text-muted">
                  No ideas submitted yet, use the '+' button to submit an idea.
                </h3>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="text-center" v-if="$apollo.loading">
          <b-col>
            <h1>
              <i class="fas fa-spinner fa-spin ml-1"></i>
            </h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { debounce } from "debounce";

// Components
import HackathonListIdeaCard from "@/views/Components/Hackathon/Idea/ListIdeaCard.vue";
import PrizesWidget from "@/views/Components/Hackathon/Prizes/PrizesWidget.vue";
import GoalsWidget from "@/views/Components/Hackathon/Goals/GoalsWidget.vue";

// Queries
import { GET_HACKATHON_HACKATHON_AND_IDEAS_UNAUTH } from "@/graphql/queriesUnAuth";

// ORM
import { processIdeaRelayData, Idea } from "@/ORM/Hackathon/Idea.js";
import {
  processHackathonRelayData,
  Hackathon,
} from "@/ORM/Hackathon/Hackathon.js";

export default {
  name: "ViewHackathon",
  components: {
    HackathonListIdeaCard,
    PrizesWidget,
    GoalsWidget,
  },
  apollo: {
    get_hackathon_hackathon: {
      client: "apolloClientUnAuth",
      query: GET_HACKATHON_HACKATHON_AND_IDEAS_UNAUTH,
      result(res) {
        if (res.data.hackathonHackathon == null) {
          this.$router.push({
            path: "/login",
          });
          return;
        }

        this.hackathon = processHackathonRelayData(res);
        this.hackathon_ideas = this.hackathon.ideas;
        this.filtered_hackathon_ideas = this.hackathon.ideas;
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          hackathon_id: this.hackathon_id,
          hackathon_uid: this.hackathon_uid,
        };
      },
      update(data) {
        this.apollo_data.get_hackathon_hackathon = data;
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {
        get_hackathon_hackathon: {},
      },
      hackathon_id: null,
      hackathon_uid: null,
      hackathon: new Hackathon(),
      hackathon_profile: {
        id: null,
      },
      hackathon_ideas: [],
      filtered_hackathon_ideas: [],
      is_part_of_team: false,

      search: {
        search_term: "",
        is_loading: false,
      },
    };
  },
  methods: {
    // Search Functionality

    on_keyup_search() {
      this.search.is_loading = true;
      this.debounced_on_keyup_search();
    },

    debounced_on_keyup_search: debounce(function () {
      this.$refs.search_form_submit.click();
    }, 1000),
    on_search_submit() {
      if (this.search.search_term === "") {
        this.filtered_hackathon_ideas = this.hackathon_ideas;
        setTimeout(() => {
          this.search.is_loading = false;
        }, 500);
      } else {
        this.filtered_hackathon_ideas = this.filter_hackathon_ideas(
          this.search.search_term,
          this.hackathon_ideas
        );
        this.search.is_loading = false;
      }
    },

    filter_hackathon_ideas(search_term, in_array) {
      let out_array = in_array.filter((item) => {
        if (item.name === undefined) {
          item.name = "";
        }
        if (item.detail_description === undefined) {
          item.detail_description = "";
        }

        return (
          item.name.toLowerCase().includes(search_term.toLowerCase()) ||
          item.description.toLowerCase().includes(search_term.toLowerCase())
        );
      });
      return out_array;
    },

    // Apollo Methods
    enable_query(query) {
      if (!query.skip) {
        query.refetch();
      } else {
        query.skip = false;
        query.start();
      }
    },

    // ID Management
    get_hackathon_id() {
      if (this.$route.params.hackathon_id) {
        this.hackathon_id = this.$route.params.hackathon_id;
        return this.$route.params.hackathon_id;
      } else {
        this.hackathon_id = null;
        return null;
        // TODO: We need to reroute this if there is no hackathon. Possibly to the discover hackathons page.
      }
    },
    get_hackathon_uid() {
      if (this.$route.query.token) {
        this.hackathon_uid = this.$route.query.token;
        return this.$route.query.token;
      } else {
        this.hackathon_uid = null;
        return null;
        // TODO: We need to reroute this if there is no hackathon. Possibly to the discover hackathons page.
      }
    },

    get_url_data() {
      if (this.get_hackathon_id() && this.get_hackathon_uid()) {
        this.enable_query(this.$apollo.queries.get_hackathon_hackathon);
      } else if (this.get_hackathon_id()) {
        this.enable_query(this.$apollo.queries.get_hackathon_hackathon);
      }
      this.redirect_if_logged_in();

      // TODO: We need to reroute this if there is no hackathon. Possibly to the discover hackathons page.
    },

    redirect_if_logged_in() {
      if (store.getters.isLogin) {
        setTimeout(() => {
          this.$router.push({
            path:
              "/hackathon/" + encodeURIComponent(this.hackathon_id) + "/detail",
          });
        }, 20);
      }
    },

    getUser() {
      this.int_user = utils.deepcopy(this.$store.getters.getUser);
    },
    // Apollo Methods
    handleAllIdeaIdea(data) {
      this.ideasList = [];
      data.forEach((i) => {
        this.ideasList.push({
          name: i.node.name,
          description: i.node.description,
          detail_description: i.node.detailDescription,
          id: i.node.id,
          team: [],
          like_count: i.node.numberOfLikes,
          creator_id: i.node.creator.id,
        });
      });
    },
  },

  mounted() {
    this.get_url_data();
  },
};
</script>

<style></style>
